<template>
  <div class="">
    <div @click="toggle" class="mobile_header show_on_mobile pointer noselect">
      <s-icon width="20" color="white">calendar-week</s-icon>
      <div class="uppercase">{{ $t("schedule.meeting_details") }}</div>
    </div>
    <div ref="dropme" class="grid dropme">
      <div class="hide_on_mobile center">
        <s-icon color="grey" height="20">calendar-week</s-icon>
      </div>
      <div class="hide_on_mobile align-center font-14 opacity-60 uppercase">
        <div :title="tip && $t('schedule.tip.meeting_name')">
          {{ $t("schedule.meeting_name") }}
        </div>
      </div>
      <div class="item1 pt-10">
        <s-text-field
          :height="isMobile"
          v-model="title"
          :autofocus="pastEvent"
          :noedit="!pastEvent"
          :disabled="pastEvent"
          :error="error.title"
          block
          :placeholder="$t('schedule.enter_meeting_title')"
        ></s-text-field>
      </div>
      <div class="align-center item5 font-14 opacity-60 uppercase">
        <div :title="tip && $t('schedule.tip.meeting_time')">
          {{ $t("schedule.meeting_time") }}
        </div>
      </div>
      <div :class="{ item3: isTablet }" class="d-flex">
        <s-date-picker
          v-model="date"
          :prefill="date"
          @changeDate="changeDate"
          :noedit="!pastEvent"
          :height="isMobile"
          class="mr-10"
        ></s-date-picker>
        <s-time-picker
          v-model="time"
          :prefill="time"
          :timezone="timezones"
          @changeTime="changeTime"
          :noedit="!pastEvent"
          :height="isMobile"
          class="w60"
        ></s-time-picker>
      </div>
      <div
        :class="{ item2: isTablet }"
        class="align-center font-14 opacity-60 uppercase"
      >
        <div :title="tip && $t('schedule.tip.duration')">
          {{ $t("schedule.duration") }}
        </div>
      </div>
      <div :class="{ item3: isTablet }">
        <s-dropdown
          v-model="option"
          :items="options"
          :height="isMobile"
          :noedit="pastEvent"
        ></s-dropdown>
      </div>
      <div class="item5 align-center font-14 opacity-60">
        <div :title="tip && $t('schedule.tip.meeting_room')">
          {{ $t("schedule.meeting_room") }}
        </div>
      </div>
      <div :class="{ item3: isTablet }">
        <s-dropdown
          v-model="input"
          :items="roomsItems"
          :height="isMobile"
          :noedit="pastEvent"
        ></s-dropdown>
      </div>
      <div
        class="item4 font-12 align-center hide_on_mobile space-between"
        :class="{ item6: isTablet }"
      >
        <button
          type="button"
          @click="openTimezones"
          class="green--text font-14 mr-10 nowrap pointer linkHover"
          :title="tip && $t('schedule.tip.timezone')"
          :class="{ disabled: pastEvent }"
        >
          {{ $t("schedule.timezone") }}
        </button>
        <button
          type="button"
          @click="openRepeating"
          class="green--text font-14 mr-10 nowrap pointer linkHover"
          :class="{ disabled: editMode }"
          :title="tip && $t('schedule.tip.custom')"
        >
          {{ $t("schedule.custom") }}
        </button>
      </div>
      <div
        v-if="!$route.params.edit"
        :title="tip && $t('schedule.repeating')"
        class="item5 align-center font-14 opacity-60 show_on_mobile"
      >
        {{ $t("schedule.repeating") }}
      </div>
      <s-dropdown
        v-if="!$route.params.edit"
        class="show_on_mobile w100"
        v-model="repeatingValue"
        :items="data"
        :height="isMobile"
        :noedit="pastEvent"
      ></s-dropdown>
      <div
        class="font-14 align-center opacity-60 show_on_mobile"
        v-if="!pastEvent && isMobile"
      >
        {{ $t("schedule.meeting_timezone") }}
      </div>
      <s-timezone
        height="40"
        v-model="timezone"
        v-if="!pastEvent && isMobile"
        :prefill="timezones"
        class="show_on_mobile"
      ></s-timezone>
      <div class="font-14 align-center opacity-60 show_on_mobile">
        {{ $t("schedule.meeting_link") }}
      </div>
      <div class="d-flex pb-20 show_on_mobile">
        <s-text-field v-model="link" disabled block type="text" ref="link" />
        <div @click="copy" class="box center">
          <s-icon width="20" color="white">content-copy</s-icon>
        </div>
      </div>
    </div>
    <hr class="my-10 hide_on_mobile" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
import dayjs from "dayjs";

export default {
  props: ["right"],
  data() {
    return {
      isMobile: true,
      isTablet: false,
      input: "",
      fullLink: "",
      expand: true,
      repeatingValue: "weekly",
      timezone: "",
      option: "",
      data: [
        {
          display: this.$t("schedule.no_repeat"),
          value: "none",
          selected: true,
        },
        {
          display: this.$t("schedule.daily"),
          value: "daily",
          selected: false,
        },
        {
          display: this.$t("schedule.weekly"),
          value: "weekly",
          selected: false,
        },
        {
          display: this.$t("schedule.monthly"),
          value: "monthly",
          selected: false,
        },
        {
          display: this.$t("schedule.annually"),
          value: "annually",
          selected: false,
        },
        {
          display: this.$t("schedule.everyday"),
          value: "workdays",
          selected: false,
        },
      ],
    };
  },
  methods: {
    listen(val) {
      let offsetWidth = document.getElementsByClassName("left")[0].offsetWidth;
      if (window.innerWidth > 576) {
        this.isMobile = 30;
      } else {
        this.isMobile = 40;
      }

      this.isTablet = offsetWidth <= 758;
    },
    changeDate(val) {
      this.$store.commit("schedule/setDatetimeDate", val);
    },
    changeTime(val) {
      this.$store.commit("schedule/setDatetimeTime", val);
    },
    toggle() {
      this.expand = !this.expand;
      if (this.$refs.dropme.clientHeight === 0) {
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transformOrigin: "top",
        });
      } else {
        gsap.to(this.$refs.dropme, {
          scaleY: 0,
          height: 0,
          opacity: 0,
          duration: 0.2,
          ease: "power3.out",
          transformOrigin: "top",
        });
      }
    },
    openTimezones() {
      if (this.pastEvent) return;
      this.$store.commit("modals/timezoneModal", true);
    },
    openRepeating() {
      if (this.$route.params.edit) return;
      this.$store.commit("modals/repeatingModal", true);
    },
    async copy() {
      await navigator.clipboard.writeText(this.fullLink);
    },
  },
  computed: {
    ...mapState("user", ["meetServer", "tip", "userTimezone"]),
    ...mapState("schedule", [
      "rooms",
      "timezones",
      "error",
      "repeating",
      "repeatingUID",
      "datetime",
      "pastEvent",
      "originalDateTime",
    ]),
    options() {
      let data = [
        { display: this.$t("atoms.times.15_min"), value: "0:15" },
        { display: this.$t("atoms.times.30_min"), value: "0:30" },
        { display: this.$t("atoms.times.1_hour"), value: "1:00" },
        { display: this.$t("atoms.times.2_hour"), value: "2:00" },
      ];
      let selected = this.$store.state.schedule.duration;
      if (!selected) data[2].selected = true;
      if (!selected) return data;
      if (!selected) data[2].selected = false;
      let index = data.findIndex((el) => el.value == selected);
      if (index == -1) index = 2;
      data[index].selected = true;
      return data;
    },
    state() {
      return this.$store.state.toolbars.sidebar;
    },
    roomsItems() {
      let data = this.$store.state.rooms.rooms;
      data = JSON.parse(JSON.stringify(data));

      for (let x in data) {
        if (this.rooms == data[x].id) data[x].selected = true;
        data[x].display = data[x].title;
      }
      if (!this.pastEvent) {
        return data.filter((el) => !el.isDeleted);
      } else {
        return data;
      }
    },
    editMode() {
      return this.$route.params.edit === "edit";
    },
    title: {
      get() {
        let title = this.$store.state.schedule.title;
        if (title !== "") return title;
        this.$store.commit("schedule/setTitle", title);
        return title;
      },
      set(val) {
        this.$store.commit("schedule/setTitle", val);
      },
    },
    date() {
      let timezone = this.timezones;
      if (!this.timezones) timezone = this.userTimezone;
      let date = dayjs(this.originalDateTime).tz(timezone);
      let auto = this.generateDateTime.format("MMM D, YYYY");
      return this.$route.params.edit || this.originalDateTime
        ? date.format("MMM D, YYYY")
        : auto;
    },
    time() {
      let timezone = this.timezones;
      if (!this.timezones) timezone = this.userTimezone;
      let time = dayjs(this.originalDateTime).tz(timezone);
      let auto = this.generateDateTime.format("h:mm A");
      return this.$route.params.edit || this.originalDateTime
        ? time.format("h:mm A")
        : auto;
    },
    generateDateTime() {
      let time = dayjs() //.tz(this.userTimezone);
      for (let x = 0; x < 16; x++) {
        let format = time.format("mm");
        if (format % 5 == 0) break;
        time = time.add(1, "minute");
      }
      return time.add(5, "minute");
    },
    link() {
      let val;
      for (let x of this.roomsItems) {
        if (x.id === this.input.id)
          val = "https://" + this.meetServer + "/" + x.url;
      }
      if (!val) return;
      this.fullLink = val;
      if (val.length > 30) return val.slice(0, 30) + "...";
      return val;
    },
  },
  watch: {
    state(val) {
      this.listen();
    },
    title(val) {
      this.title = val.trim();
    },
    right(val) {
      this.listen();
    },
    rooms(val) {
      this.$store.commit("schedule/setRoom", val);
    },
    repeatingValue(val) {
      this.$store.commit("schedule/setRepeating", val.value);
    },
    input(val) {
      this.$store.commit("schedule/setRoom", val.id);
    },
    timezone(val) {
      if (!val) return;
      this.$store.commit("schedule/setTimezone", val);
    },
    timezones(val) {
      this.timezone = val;
      return;
    },
    option(val) {
      this.$store.commit("schedule/setDuration", val.value);
    },
    isMobile(val) {
      this.expand = true;
      if (val === 30)
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transform: "none",
          onComplete: () => (this.$refs.dropme.style.transform = "none"),
        });
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.listen);
  },
  mounted() {
    this.listen();
    window.addEventListener("resize", this.listen);
    this.timezone = this.timezones;
    if (!this.repeating) return;
    this.data.forEach((val) => (val.selected = false));
    let active = this.data.filter((val) => val.value == this.repeating)[0];
    if (this.repeating) active.selected = true;
  },
};
</script>

<style scoped lang='less'>
.link {
  height: 40px;
  background-color: #151b22;
  font-size: 14px;
  color: #409875;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.disabled {
  color: grey;
  cursor: not-allowed;
  user-select: none;
}

.grid {
  display: grid;
  grid-template-columns: 20px 150px 2fr 80px 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

.item1 {
  grid-column: 3 / span 3;
}
.item2 {
  grid-column: 2 / span 1;
}

.item3 {
  grid-column: 3 / span 3;
}

.item4 {
  grid-column: 4 / span 2;
}

.item5 {
  grid-column: 2;
}

.item6 {
  grid-column: 3 / span 3;
}

.mobile_header {
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #47657f;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.box {
  min-width: 40px;
  height: 40px;
  background-color: #151b22;
  margin-left: 2px;
}

.linkHover:hover {
  font-weight: 600;
}

.linkHover:focus {
  font-weight: 600;
  text-decoration: underline;
}

@media only screen and (min-width: 576px) {
  .show_on_mobile {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }
}
</style>
