<template>
  <div>
    <div @click="toggle" class="mobile_header show_on_mobile pointer noselect">
      <s-icon width="20" color="white">bell-outline</s-icon>
      <div class="uppercase">{{ $t("schedule.notifications_sm") }}</div>
    </div>
    <div ref="dropme" class="grid dropme">
      <div class="hide_on_mobile center">
        <s-icon color="grey" height="20">bell-outline</s-icon>
      </div>
      <div class="align-center font-14 opacity-60 uppercase">
        <div :title="tip && $t('schedule.tip.notification')">
          {{ $t("schedule.notifications_lg") }}
        </div>
      </div>
      <div class="wrapper item1" v-if="!pastEvent">
        <s-dropdown
          class="grow"
          :items="itemsType"
          v-model="type"
          :height="isMobile"
          :noedit="pastEvent"
        ></s-dropdown>
        <s-dropdown
        class="grow"
          :items="options"
          v-model="time"
          :height="isMobile"
          :noedit="pastEvent"
        ></s-dropdown>
        <s-btn
          @click.native="add()"
          :height="isMobile"
          type="button"
          width="110"
          >{{ $t("schedule.add") }}</s-btn
        >
      </div>
      <div class="item1 mt-10 missingText sm-margin">
        <div
          v-for="(item, index) in items.slice().reverse()"
          :key="index"
          class="items align-center"
        >
          {{ index + 1 }}. {{ item.text }}
          <button type="button" class="center close" @click="del(index)">
            <s-icon height="16" v-if="!pastEvent" color="white">close</s-icon>
          </button>
        </div>
      </div>
    </div>
    <hr class="my-10 hide_on_mobile" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  props: ["right"],
  data() {
    return {
      isMobile: false,
      isTablet: false,
      expand: true,
      type: "",
      time: "",
      items: [],
    };
  },
  methods: {
    listen(val) {
      let offsetWidth = document.getElementsByClassName("left")[0].offsetWidth;
      if (window.innerWidth > 576) {
        this.isMobile = 30;
      } else {
        this.isMobile = 40;
      }

      this.isTablet = offsetWidth <= 758;
    },
    add() {
      let flag = false;
      let data = {
        text: this.type.display + this.$t("schedule.set") + this.time.display,
        type: this.type.value,
        time: this.time.vTime,
        timeType: this.time.vType,
      };

      for (let x of this.items) {
        let con1 = x.time == data.time;
        let con2 = x.timeType == data.timeType;
        let con3 = x.type == data.type;
        if (con1 && con2 && con3) flag = true;
      }
      if (!flag) {
        this.$store.commit("notifications/push", {
          success: this.$t("server.notification_added"),
        });
      }
      if (flag) {
        this.$store.commit("notifications/push", {
          error: this.$t("server.notification_not_added"),
        });
      }
      if (!flag) this.items.push(data);

      let newObject = JSON.parse(JSON.stringify(this.items));
      for (let x of newObject) {
        delete x["text"];
      }
      this.$store.commit("schedule/setNotification", newObject);
    },
    del(val) {
      this.items.reverse().splice(val, 1);
      this.items.reverse();

      let newObject = JSON.parse(JSON.stringify(this.items));
      for (let x of newObject) {
        delete x["text"];
      }
      this.$store.commit("schedule/setNotification", newObject);
    },
    toggle() {
      this.expand = !this.expand;
      if (this.$refs.dropme.clientHeight == 0) {
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transformOrigin: "top",
        });
      } else {
        gsap.to(this.$refs.dropme, {
          scaleY: 0,
          height: 0,
          opacity: 0,
          duration: 0.2,
          ease: "power3.out",
          transformOrigin: "top",
        });
      }
    },
    getToLocal() {
      for (let x of this.notification) {
        let result = this.options.filter((val) => {
          let con1 = Number(val.vTime) == Number(x.time);
          let con2 = val.vType.toLowerCase() == x.timeType.toLowerCase();
          return con1 && con2;
        })[0];

        let email = this.$t("schedule.email_notification");
        email = email + this.$t("schedule.set") + " " + result.display;

        let data = {
          text: email,
          type: x.type,
          time: String(x.time),
          timeType: x.timeType,
        };
        this.items.push(data);
      }
    },
    setToRemote() {
      let data = {
        text:
          this.$t("schedule.email_notification") +
          this.$t("schedule.set") +
          " " +
          this.$t("schedule.15_min_before"),
        type: "email",
        time: "15",
        timeType: "minutes",
      };
      this.items.push(data);
      this.$store.commit("schedule/setNotification", [data]);
    },
  },
  computed: {
    state() {
      return this.$store.state.toolbars.sidebar;
    },
    options() {
      return [
        {
          display: this.$t("schedule.15_min_before"),
          value: "15_min_before",
          vTime: 15,
          vType: "minutes",
        },
        {
          display: this.$t("schedule.30_min_before"),
          value: "30_min_before",
          vTime: 30,
          vType: "minutes",
        },
        {
          display: this.$t("schedule.1_hour_before"),
          value: "1_hour_before",
          vTime: 1,
          vType: "hour",
        },
        {
          display: this.$t("schedule.3_hour_before"),
          value: "3_hour_before",
          vTime: 3,
          vType: "hour",
        },
        {
          display: this.$t("schedule.1_day_before"),
          value: "1_day_before",
          vTime: 1,
          vType: "day",
        },
      ];
    },
    itemsType() {
      return [
        { display: this.$t("schedule.email_notification"), value: "email" },
      ];
    },
    // types: {
    //   get() {
    //     let data = [

    //     ];
    //     data[0].selected = true;
    //     return data;
    //   },
    //   set(val) {
    //     // this.$store.commit("schedule/setDuration", val.value);
    //   },
    // },
    // types() {
    //   return [
    //     { display: this.$t("schedule.email_notification"), value: "email" },
    //   ];
    // },
    ...mapState("schedule", ["notification", "edit", "datetime", "pastEvent"]),
    ...mapState("notifications", ["notifications"]),
    ...mapState("user", ["lang", "tip"]),
  },
  watch: {
    state(val) {
      this.listen();
    },
    right(val) {
      this.listen();
    },
    lang(val) {
      this.items = [];
      this.getToLocal();
    },
    isMobile(val) {
      this.expand = true;
      if (val == 30)
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transform: "none",
          onComplete: () => (this.$refs.dropme.style.transform = "none"),
        });
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.listen);
  },
  mounted() {
    this.listen();
    window.addEventListener("resize", this.listen);
    if (!this.$route.params.edit) {
      this.setToRemote();
    } else {
      this.getToLocal();
    }
  },
};
</script>

<style scoped>
.mobile_header {
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #47657f;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: 20px 150px auto;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

.wrapper {
  display: grid;
  gap: 10px;
}

.item1 {
  grid-column: 3;
}
.items {
  font-size: 14px;
  color: #74b7e4;
  justify-content: space-between;
  max-width: 460px;
}

.close {
  transition: all ease 0.2s;
}

.close:focus,
.close:hover {
  transform: scale(1.2);
}

@media only screen and (min-width: 576px) {
  .show_on_mobile {
    display: none;
  }
}

@media only screen and (min-width: 879px) {
  .wrapper {
    display: flex;
  }
}

@media only screen and (min-width: 1311px) and (max-width: 1453px) {
  .wrapper {
    display: grid;
  }
}

@media only screen and (max-width: 576px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }

  .sm-margin {
    margin-bottom: 10px;
  }
}
</style>
