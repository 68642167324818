<template>
  <div class="controls pt-15">
    <div class="d-flex pb-15 section">
      <s-btn
        :block="isMobile"
        icon
        type="button"
        @click.native="post"
        class="mx-7"
        :disabled='block'
        v-if="!pastEvent"
        :class="{ green: isPastEvent }"
      >
        <s-icon width="20" class="pr-10" color="white">calendar-check</s-icon>
        <div class="grow">
          {{ action }}
        </div>
      </s-btn>
      <s-btn
        icon
        :block="isMobile"
        type="button"
        class="mx-7"
        ref="cancel"
        @click.native="cancel"
      >
        <s-icon width="20" class="pr-10" color="white">close-circle</s-icon>
        <div class="grow">
          {{ $t("schedule.cancel") }}
        </div>
      </s-btn>
      <s-btn
        icon
        v-if="edit && !pastEvent"
        :block="isMobile"
        type="button"
        class="mx-7 red"
        @click.native="del"
      >
        <s-icon width="20" class="pr-10" color="white">delete-outline</s-icon>
        <div class="grow">
          {{ $t("schedule.delete") }}
        </div>
      </s-btn>
    </div>
    <hr class="hr" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      edit: false,
      validationFlag: false,
      block: false,
    };
  },
  methods: {
    async post() {
      if (!this.isPastEvent) return this.notify();
      this.onClickValidate();
      if (this.activateValidation()) return;
      this.validationFlag = false;
      if (!this.edit) return this.postEvent();
      if (!this.repeatingUID) return this.updateEvent();
      if (this.edit) return this.editEvent();
    },
    async postEvent() {
      this.block = true;
      await this.$store.dispatch("schedule/postSchedule");
      this.block = false;
      return this.$store.dispatch("calendar/goToLastCalendarView");
    },
    async updateEvent() {
      this.block = true;
      await this.$store.dispatch("schedule/updateEvent");
      this.block = false;
      return this.$store.dispatch("calendar/goToLastCalendarView");
    },
    editEvent() {
      this.$store.commit("modals/saveEventConfirmation", true);
    },
    notify() {
      return this.$store.commit("notifications/push", {
        error: this.$t("atoms.not_past"),
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    async del() {
      if (!this.repeatingUID)
        this.$store.commit("modals/deleteEventSimpleConfirmation", true);
      else this.$store.commit("modals/deleteEventConfirmationModal", true);
    },
    assign() {
      this.edit = this.$route.params.edit;
    },
    activateValidation() {
      this.validationFlag = true;
      return this.validate();
    },
    validate() {
      let str = this.$t("schedule.min_5_char");
      let ob = {};
      if (this.title.length < 5) ob = { title: str };
      if (Object.keys(ob).length > 0) {
        this.$store.commit("schedule/setError", ob);
        return true;
      } else {
        this.$store.commit("schedule/setError", []);
        return false;
      }
    },
    onClickValidate() {
      let str = this.$t("schedule.min_5_char");
      let ob = {};
      if (this.title.length < 5) ob = { title: str };
      if (Object.keys(ob).length > 0) {
        this.$store.commit("schedule/setError", ob);
        this.$store.commit("notifications/push", { error: str });
        return true;
      } else {
        this.$store.commit("schedule/setError", []);
        return false;
      }
    },
  },
  computed: {
    ...mapState("user", ["userTimezone"]),
    ...mapState("schedule", [
      "title",
      "repeatingUID",
      "datetime",
      "timezones",
      "pastEvent",
    ]),
    action() {
      return this.edit ? this.$t("schedule.save") : this.$t("schedule.create");
    },
    isPastEvent() {
      if (!this.datetime) return;
      let timezone = this.timezones;
      if (!this.timezones) timezone = this.userTimezone;
      let day = dayjs(this.datetime).tz(timezone, true);
      return !day.isBefore(dayjs().tz(timezone));
    },
    isMobile() {
      return this.$mq !== "sm";
    },
  },
  watch: {
    $route() {
      this.assign();
    },
    title() {
      if (this.validationFlag) return this.validate();
    },
  },
  created() {
    let con1 = this.$router.currentRoute.name !== "Schedule";
    let con2 = !dayjs(this.datetime).isValid();
    if (con1 && con2) this.$router.push("/schedule");
  },
  async mounted() {
    this.assign();
    await this.$wait();
    if (this.pastEvent) this.$refs.cancel.onFocus();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 576px) {
  .controls {
    background-color: #141925;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 0;
    margin-bottom: 0;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 0;
  }

  .section {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hr {
    display: none;
  }
}
</style>