<template>
  <div>
    <div @click="toggle" class="mobile_header show_on_mobile pointer noselect">
      <s-icon width="20" color="white">lock-open</s-icon>
      <div class="uppercase">{{ $t("schedule.password_options_sm") }}</div>
    </div>
    <div ref="dropme" class="dropme">
      <div class="grid sm-pb-20">
        <div class="hide_on_mobile center">
          <s-icon color="grey" height="20">lock-open</s-icon>
        </div>
        <div class="align-center font-14 opacity-60 pt-10 uppercase">
          <div :title="tip && $t('schedule.tip.password')">
            {{ $t("schedule.password_options_lg") }}
          </div>
        </div>
        <div class="d-flex wrap item1 align-center" v-if="!pastEvent">
          <radio v-model="option" />
        </div>
        <div :class="{ item1: isTablet, item2: !isTablet }">
          <div class="d-flex w100">
            <s-text-field
              ref="password"
              :placeholder="placeholder"
              :title='tip && title'
              block
              bar
              :password="pastEvent"
              :noedit="!pastEvent"
              :disabled="disabled || pastEvent"
              v-model="password"
              :height="isMobile"
            ></s-text-field>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-10 hide_on_mobile" />
  </div>
</template>

<script>
import radio from "./Radio";
import { gsap } from "gsap";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  components: {
    radio,
  },
  props: ["right"],
  data() {
    return {
      isMobile: false,
      isTablet: false,
      option: "",
      disabled: true,
      placeholder: "",
      expand: false,
      customPassword: "",
      title: ""
    };
  },
  methods: {
    listen(val) {
      let offsetWidth = document.getElementsByClassName("left")[0].offsetWidth;
      if (window.innerWidth > 576) {
        this.isMobile = 30;
      } else {
        this.isMobile = 40;
      }

      if (offsetWidth > 758) {
        this.isTablet = false;
      } else {
        this.isTablet = true;
      }
    },
    generatePassword() {
      let length = 16,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    toggle() {
      this.expand = !this.expand;
      if (this.$refs.dropme.clientHeight == 0) {
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transformOrigin: "top",
        });
      } else {
        gsap.to(this.$refs.dropme, {
          scaleY: 0,
          height: 0,
          opacity: 0,
          duration: 0.2,
          ease: "power3.out",
          transformOrigin: "top",
        });
      }
    },
    async passwordOptions(val) {
      if (val == "one") {
        this.password = "";
        this.disabled = true;
        this.placeholder = this.$t("schedule.not_protected");
        this.title = this.$t("schedule.tip.password1");
      }
      if (val == "two") {
        if (this.customPassword) this.password = this.customPassword;
        if (!this.customPassword) this.password = "";
        this.disabled = false;
        this.placeholder = this.$t("modal.newMeeting.enter_password");
        this.title = this.$t("schedule.tip.password2");
        await this.$wait();
        if (!this.pastEvent) this.$refs.password.onFocus();
      }
      if (val == "three") {
        this.password = this.generatePassword();
        this.disabled = true;
        this.title = this.$t("schedule.tip.password3");
      }
    },
    passwordOptionsPlaceholder(val) {
      if (val == "one") {
        this.placeholder = this.$t("schedule.not_protected");
      }
      if (val == "two") {
        this.placeholder = this.$t("modal.newMeeting.enter_password");
      }
    },
  },
  computed: {
    state() {
      return this.$store.state.toolbars.sidebar;
    },
    password: {
      get() {
        return this.$store.state.schedule.password;
      },
      set(val) {
        this.$store.commit("schedule/setPassword", val);
      },
    },
    ...mapState("schedule", ["originalpassword", "datetime", "pastEvent"]),
    ...mapState("user", ["lang", "tip"]),
  },
  watch: {
    state(val) {
      this.listen();
    },
    right(val) {
      this.listen();
    },
    lang(val) {
      this.passwordOptionsPlaceholder(this.option);
    },
    isMobile(val) {
      this.expand = true;
      if (val == 30)
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transform: "none",
          onComplete: () => (this.$refs.dropme.style.transform = "none"),
        });
    },
    option(val) {
      this.passwordOptions(val);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.listen);
  },
  async mounted() {
    this.customPassword = this.originalpassword;
    this.listen();
    window.addEventListener("resize", this.listen);
    this.option = this.password.length > 0 ? "two" : "one";
  },
};
</script>

<style scoped>
.mobile_header {
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #47657f;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: 20px 150px 2fr 80px 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

.item1 {
  grid-column: 3 / span 3;
}

.item2 {
  grid-column: 3;
}

.box {
  min-width: 30px;
  height: 30px;
  background-color: #151b22;
  margin-left: 2px;
}

.box_big {
  min-width: 40px;
  height: 40px;
}

@media only screen and (min-width: 576px) {
  .show_on_mobile {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }
}
</style>