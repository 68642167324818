<template>
  <div>
    <div @click="toggle" class="mobile_header show_on_mobile pointer noselect">
      <s-icon width="20" color="white">email-open</s-icon>
      <div class="uppercase">{{ $t("schedule.invitation_details") }}</div>
    </div>
    <div ref="dropme" class="dropme">
      <div class="grid">
        <div class="hide_on_mobile center">
          <s-icon color="grey" height="20">email-open</s-icon>
        </div>
        <div class="align-start font-14 uppercase opacity-60 hide_on_mobile">
          <div :title="tip && $t('schedule.tip.details')">
            {{ $t("schedule.invitation_details") }}
          </div>
        </div>
        <div class="align-center font-14 opacity-60 nowrap">
          {{ $t("schedule.name") }}
        </div>
        <div
          class="align-center font-14 missingText"
          :class="{ item1: isTablet }"
        >
          {{ title }}
        </div>

        <div class="align-center font-14 opacity-60 item2 nowrap">
          {{ $t("schedule.time") }}
        </div>
        <div
          class="align-center font-14 weight-300 gap-10 d-flex wrap"
          :class="{ item1: isTablet }"
        >
          <div class="row gap-5">
            <span>{{ formatDate(datetime) }} </span>
            <span>{{ $t("schedule.from") }} </span>
            <span>{{ formatTime(datetime) }} </span>
            <span>{{ $t("schedule.for") }}</span>
            <span> {{ durationValue }}</span>
          </div>
          <!-- <span class="green--text">{{ userTimezone }}</span> -->
        </div>
        <div class="align-center font-14 opacity-60 item2 nowrap">
          {{ $t("schedule.meeting_timezone_sm") }}
        </div>
        <div
          class="align-center font-14 weight-300"
          :class="{ item1: isTablet }"
        >
          {{ timezone }}
        </div>
        <div class="align-center font-14 opacity-60 item2 nowrap">
          {{ $t("schedule.room") }}
        </div>
        <div
          class="align-center font-14 weight-300"
          :class="{ item1: isTablet }"
        >
          {{ nameOfRoom }}
        </div>
        <div class="align-cente(r font-14 opacity-60 item2 nowrap">
          {{ $t("schedule.event") }}
        </div>
        <div
          class="align-center font-14 weight-300"
          :class="{ item1: isTablet }"
        >
          {{ formattedRepeating }}
        </div>
        <div
          v-if="this.$route.params.edit && !pastEvent"
          class="align-cente(r font-14 opacity-60 item2 nowrap"
        >
          {{ $t("schedule.link") }}
        </div>
        <div
          v-if="this.$route.params.edit && !pastEvent"
          class="align-center font-14 weight-300 green--text"
          :class="{ item1: isTablet }"
        >
          {{ meetingLink | link }}
        </div>
        <div class="align-center font-14 opacity-60 item2 nowrap">
          {{ $t("schedule.password") }}
        </div>
        <div
          class="align-center font-14 weight-300"
          :class="{ item1: isTablet }"
        >
          {{ passwordMethod(password) }}
        </div>

        <div class="font-14 opacity-60 item2 nowrap">
          {{ $t("schedule.description") }}
        </div>
        <s-text-area
          :disabled="pastEvent"
          v-model="description"
          :class="{ item1: isTablet }"
        ></s-text-area>
      </div>
      <hr v-if="this.$route.params.edit && !pastEvent" class="mt-10 mb-10" />
      <div v-if="this.$route.params.edit && !pastEvent" class="grid sm-pb-60">
        <div class="item3">
          <s-btn block type="button" @click.native="copy">
            <s-icon width="20" class="pr-10" color="white">link-variant</s-icon>
            <div class="grow">
              {{ $t("schedule.copy") }}
            </div>
          </s-btn>
        </div>
        <div class="font-12 italic opacity-60 align-center sm-pb-10">
          {{ $t("schedule.share") }}
        </div>
      </div>
    </div>
    <hr class="mt-10 mb-10 hide_on_mobile" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
import ob from "../Modals/Repeating";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default {
  props: ["right"],
  data() {
    return {
      isMobile: false,
      isTablet: false,
      expand: true,
      items: [{ text: "1. Email Notification set 1 Hour Before Meeting" }],
    };
  },
  methods: {
    listen(val) {
      let offsetWidth = document.getElementsByClassName("left")[0].offsetWidth;
      if (window.innerWidth > 576) {
        this.isMobile = 30;
      } else {
        this.isMobile = 40;
      }

      if (offsetWidth > 759) {
        this.isTablet = false;
      } else {
        this.isTablet = true;
      }
    },
    async copy() {
      this.$store.commit("notifications/push", {
        success: "Copied to clipboard",
      });
      await navigator.clipboard.writeText(this.meetingLink);
    },
    passwordMethod(val) {
      let star = "";
      for (let x of val) {
        star += "*";
      }
      return val.length > 0 ? star : this.$t("schedule.not_protected");
    },
    convertToSystemTimezone(userTimezone, eventTimezone) {
      let userTz = dayjs().tz(userTimezone).utcOffset();
      let eventTz = dayjs().tz(eventTimezone).utcOffset();
      return time.add(eventTz - userTz, "minute").format();
    },
    toggle() {
      this.expand = !this.expand;
      if (this.$refs.dropme.clientHeight == 0) {
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transformOrigin: "top",
        });
      } else {
        gsap.to(this.$refs.dropme, {
          scaleY: 0,
          height: 0,
          opacity: 0,
          duration: 0.2,
          ease: "power3.out",
          transformOrigin: "top",
        });
      }
    },
    formatDate(val) {
      return dayjs(val).format("dddd MMM D YYYY");
    },
    formatTime(val) {
      return dayjs(val).locale(this.lang).format("LT");
    },
  },
  computed: {
    state() {
      return this.$store.state.toolbars.sidebar;
    },
    roomsArray() {
      return this.$store.state.rooms.rooms;
    },
    nameOfRoom() {
      if (false) return;
      let ob = this.roomsArray.find((element) => element.id == this.rooms);
      if (!ob) return;
      return ob.title;
    },
    timezone() {
      let store = this.$store.state.schedule.timezones;
      if (store !== "") return store;
      return this.$store.commit("schedule/setTimezone", this.userTimezone);
    },
    description: {
      get() {
        return this.$store.state.schedule.description;
      },
      set(val) {
        this.$store.commit("schedule/setDescription", val);
      },
    },

    formattedRepeating() {
      return this.repeatingOptions.find((el) => el.value == this.repeating)
        .option;
    },
    repeatingOptions() {
      return [
        {
          option: this.$t("modal.custom.no_repeat"),
          value: "none",
          selected: true,
        },
        {
          option: this.$t("modal.custom.daily"),
          value: "daily",
          selected: false,
        },
        {
          option: this.$t("modal.custom.weekly"),
          value: "weekly",
          selected: false,
        },
        {
          option: this.$t("modal.custom.monthly"),
          value: "monthly",
          selected: false,
        },
        {
          option: this.$t("modal.custom.annually"),
          value: "annually",
          selected: false,
        },
        {
          option: this.$t("modal.custom.everyday"),
          value: "workdays",
          selected: false,
        },
      ];
    },
    ...mapState("user", ["lang", "userTimezone", "tip"]),
    ...mapState("schedule", [
      "title",
      "duration",
      "rooms",
      "password",
      "timezones",
      "repeating",
      "datetime",
      "meetingLink",
      "pastEvent",
    ]),
    durationValue() {
      let options = [
        { display: this.$t("atoms.times.15_min"), value: "0:15" },
        { display: this.$t("atoms.times.30_min"), value: "0:30" },
        { display: this.$t("atoms.times.1_hour"), value: "1:00" },
        { display: this.$t("atoms.times.2_hour"), value: "2:00" },
      ];
      let ob = options.find((obj) => obj.value == this.duration);
      if (!ob) return;
      return ob.display;
    },
  },
  watch: {
    state(val) {
      this.listen();
    },
    right(val) {
      this.listen();
    },
    isMobile(val) {
      this.expand = true;
      if (val == 30)
        gsap.to(this.$refs.dropme, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transform: "none",
          onComplete: () => (this.$refs.dropme.style.transform = "none"),
        });
    },
  },
  filters: {
    link(val) {
      return val.slice(0, 50) + "...";
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.listen);
  },
  mounted() {
    this.listen();
    window.addEventListener("resize", this.listen);
    this.$store.dispatch("schedule/getMeetingLink");
  },
};
</script>

<style scoped>
.mobile_header {
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #47657f;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.title {
  white-space: normal;
}

.grid {
  display: grid;
  grid-template-columns: 20px 150px min-content auto;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

.item1 {
  grid-column: 3 / span 3;
}

.item2 {
  grid-column: 3 / span 1;
}

.item3 {
  grid-column: 1 / span 3;
}

.items {
  font-size: 14px;
  color: #74b7e4;
}

.white_space_normal {
  white-space: normal;
}

@media only screen and (min-width: 576px) {
  .show_on_mobile {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .hide_on_mobile {
    display: none;
  }
}
</style>