<template>
  <div>
    <div class="mobile_header pointer noselect" v-if="!$screen.sm">
      <s-icon width="20" color="white">account-star</s-icon>
      <div class="uppercase">{{ $t("schedule.invite_attendee_sm") }}</div>
    </div>
    <div class="pb-10">
      <div class="grid pb-10 pt-10">
        <div class="center" v-if="$screen.sm">
          <s-icon color="grey" height="20">account-star</s-icon>
        </div>
        <div class="center font-14 opacity-60 uppercase" v-if="$screen.sm">
          <div :title="tip && $t('schedule.tip.invite')">
            {{ $t("schedule.invite_attendee_lg") }}
          </div>
        </div>
        <div class="align-center item1" :class="{ item8: !$screen.sm }">
          <s-icon color="green">account-star</s-icon>
          <span class="ml-10 font-14 green--text">
            {{ firstName }} {{ lastName }}
          </span>
        </div>
        <div
          class="align-center green--text font-12"
          :class="{ 'col-5': !$screen.sm }"
        >
          {{ $t("schedule.organizer") }}
        </div>
        <div
          class="row align-center gap-10"
          v-if="$screen.sm && !pastEvent"
          @click="check = !check"
        >
          <s-checkbox v-model="check"></s-checkbox>
          <span class="font-12 opacity-75 pointer align-center">{{
            $t("schedule.show_in_emails")
          }}</span>
        </div>
      </div>
      <div
        class="grid sm-pb-20"
        v-if="!$screen.sm && !pastEvent"
        @click="check = !check"
      >
        <s-checkbox v-model="check"></s-checkbox>
        <span
          class="font-12 opacity-75 pointer align-center"
          :class="{ 'pt-2': !$screen.sm }"
          >{{ $t("schedule.show_in_emails") }}</span
        >
      </div>
      <div class="grid" v-if="!pastEvent" :class="{ 'pb-20': $screen.sm }">
        <div class="d-flex item3" :class="{ item2: !$screen.sm }">
          <s-dropdown
            class="w100"
            searchable
            v-model="email"
            :items="invitees"
            attendees
            :max="eventMaximumAttendees"
            @typed="typed"
            @add="add"
            multi
            :height="$screen.sm ? 30 : 40"
          ></s-dropdown>
          <s-btn
            :height="$screen.sm ? 30 : 40"
            type="button"
            width="110"
           
            class="ml-10"
            >{{ $t("schedule.add") }}</s-btn
          >
        </div>
      </div>
    </div>
    <div v-for="(item, index) in invitees" :key="index">
      <div v-if="item.selected" class="w100 grid pb-10">
        <div
          class="font-14 blue--text align-center item1"
          :class="{ item8: !$screen.sm }"
        >
          <s-icon v-if="!item.group" color="blue">account</s-icon>
          <s-icon v-else :title="'Group'" color="blue">account-group</s-icon>
          <div v-if="!item.group" class="ml-10 nowrap overflow-hidden col">
            <span class="overflow-hidden"
              >{{ item.display || item.value }}
            </span>
            <div v-if="item.display !== item.value">
              <span
                class="font-12 opacity-75 grey--text nowrap overflow-hidden"
              >
                {{ item.value }}
              </span>
            </div>
          </div>
          <div v-else class="ml-10 col overflow-hidden nowrap">
            {{ item.display }}
            <span class="font-12 opacity-75 grey--text nowrap overflow-hidden">
              {{ item.attendees }}
            </span>
          </div>
        </div>
        <div class="font-12 align-center opacity-75 italic">
          <div v-if="!item.group">{{ $t("schedule.guest") }}</div>
          <div v-if="item.group">Group</div>
        </div>
        <div class="font-12 align-center opacity-75 italic space-between">
          <div class="mr-10 nowrap">{{ $t("schedule.email_invite") }}</div>
          <button type="button" class="center" @click="remove(index)">
            <s-icon height="16" v-if="!pastEvent" color="white">close</s-icon>
          </button>
        </div>
      </div>
    </div>
    <hr class="my-10" v-if="$screen.sm" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validateEmail } from "@/assets/functions";

export default {
  data() {
    return {
      invitees: [],
      check: true,
      email: "",
    };
  },
  methods: {
    initItems() {
      for (let x of this.attendees) {
        this.push(x.name, x.email, false, false);
      }
      for (let x of this.groups) {
        this.push(x.name, x.id, false, true, x.email);
      }
    },
    syncFromStore() {
      for (let x of this.$store.state.schedule.attendees) {
        let idx = this.invitees.findIndex((el) => el.value == x);
        if (idx >= 0) this.invitees[idx].selected = true;
        else this.push(x, x, true, false);
      }
      for (let x of this.$store.state.schedule.groups) {
        let idx = this.invitees.findIndex((el) => el.value == x);
        if (idx >= 0) this.invitees[idx].selected = true;
        else this.push(x, x, true, true);
      }
    },
    syncToStore() {
      let attendees = [];
      let groups = [];
      for (let x of this.invitees) {
        if (!x.group && x.selected) attendees.push(x.value);
        if (x.group && x.selected) groups.push(x.value);
      }
      this.$store.commit("schedule/setAttendees", attendees);
      this.$store.commit("schedule/setGroups", groups);
    },
    remove(val) {
      this.invitees[val].selected = false;
    },
    getAttendeesByEmail(email) {
      return this.attendees.find((el) => el.email == email);
    },
    sort() {
      let con1 = [(el) => el.display.toLowerCase(), "value"];
      this.invitees = _.orderBy(this.invitees, con1, ["asc", "asc"]);
    },
    push(display, value, selected, group, attendees) {
      this.invitees.push({ display, value, selected, group, attendees });
    },
    typed(val) {
      if (val) this.email = val;
    },
    add() {
      if (!this.isValid()) return;
      this.push(this.email, this.email, true, false);
      if (this.getAttendeesByEmail(this.email) >= 0) return;
      let data = { email: this.email, name: "" };
      this.$store.dispatch("attendees/postAttendee", data);
    },
    isValid() {
      let msg = "";
      let attendees = this.$store.state.schedule.attendees.length;
      let max = Number(this.eventMaximumAttendees);
      let validEmail = this.$t("schedule.enter_valid_email");
      let duplicate = this.$t("schedule.duplicate_attendee");
      if (!validateEmail(this.email)) msg = validEmail;
      if (this.getAttendeesByEmail(this.email)) msg = duplicate;
      if (this.email == "") msg = this.$t("schedule.enter_valid_name");
      if (attendees  >= max ) msg = this.$t("schedule.max_attendees");
      if (msg) return this.$store.commit("notifications/push", { error: msg });
      return true;
    },
  },
  computed: {
    ...mapState("user", [
      "firstName",
      "lastName",
      "eventMaximumAttendees",
      "tip",
    ]),
    ...mapState("toolbars", ["sidebar"]),
    ...mapState("attendees", ["attendees", "groups"]),
    ...mapState("schedule", ["datetime", "pastEvent", "showAttendees"]),
  },
  watch: {
    invitees: {
      handler() {
        this.syncToStore();
      },
      deep: true,
    },
    check() {
      this.$store.commit("schedule/setShowAttendees", this.check);
    },
  },
  mounted() {
    this.initItems();
    this.syncFromStore();
    this.sort();
    this.check = this.showAttendees;
  },
};
</script>

<style scoped>
.mobile_header {
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #47657f;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: 20px 150px 2fr 80px 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

.item1 {
  grid-column: 2 / span 2;
}

.item3 {
  grid-column: 2 / span 4;
}

.item2 {
  grid-column: 1 / span 5;
}

.item8 {
  grid-column: 1 / span 3;
}

.col-5 {
  grid-column: 5;
}
</style>