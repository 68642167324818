<template>
  <form class="page_container transitions" ref="container" id="container">
    <Controls />
    <div class="d-flex">
      <div class="left">
        <DateAndTime :right="rightPosition" />
        <Password :right="rightPosition" />
        <Notification />
        <Invites v-if="!rightPosition" />
        <Details />
      </div>
      <div v-if="rightPosition" class="right">
        <Invites />
      </div>
    </div>
  </form>
</template>

<script>
import Controls from "./ScheduleControls";
import DateAndTime from "./DateAndTime";
import Password from "./Password";
import Notification from "./Notification";
import Details from "./Details";
import Invites from "./Invites";

export default {
  data() {
    return {
      rightPosition: true,
    };
  },
  components: {
    Controls,
    DateAndTime,
    Password,
    Notification,
    Details,
    Invites,
  },
  methods: {
    listen(val) {
      var offsetWidth = document.getElementById("container").offsetWidth;
      var left = document.getElementsByClassName("left")[0];
      if (offsetWidth > 1250) {
        this.rightPosition = true;
        left.style.paddingRight = "25px";
      } else {
        this.rightPosition = false;
        left.style.paddingRight = "0";
      }
    },
  },
  computed: {
    state() {
      return this.$store.state.toolbars.sidebar;
    },
  },
  watch: {
    state(val) {
      this.listen();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.listen);
  },
  mounted() {
    this.listen();
    window.addEventListener("resize", this.listen);
    this.$store.dispatch("attendees/getAttendeesInformation");
    this.$store.dispatch("attendees/getGroups");
  },
};
</script>

<style scoped>
.container {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 100px);
  overflow: auto;
  overflow: overlay;
}

.left {
  flex-grow: 4;
  flex-basis: 0;
  padding-right: 25px;
}

.right {
  flex-grow: 3;
  flex-basis: 0;
  padding-left: 25px;
}

@media only screen and (max-width: 576px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
  }

  .page_container {
    max-height: calc(100vh - 120px);
  }

  .right {
    padding-left: 10px;
  }

  .left {
    padding-right: 10px;
  }
}
</style>