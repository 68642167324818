<template>
  <div class="schedule_page d-flex d-col h100 vh100">
    <TopToolbar />
    <div class="d-flex grow">
      <Sidebar />
      <Schedule class="grow" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Schedule from "@/components/Schedule/Schedule.vue";
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Sidebar from "@/components/Toolbars/Sidebar";
import Footer from "@/components/Toolbars/Footer.vue";

export default {
  name: "Register",
  components: {
    Schedule,
    TopToolbar,
    Sidebar,
    Footer,
  },
  beforeCreate() {
    let condition1 = this.$router.currentRoute.name == "Schedule";
    let condition2 = this.$store.state.user.routerHistory[9] !== "Schedule";
    let condition3 = this.$store.state.schedule.custom;
    if (condition1 && condition2 && !condition3) {
      this.$store.commit("schedule/resetState");
    }
  },

  beforeRouteLeave(to, from, next) {
    if (from.name == "Schedule" || from.name == "ScheduleEdit")
      this.$store.commit("schedule/resetState");
    next();
  },
};
</script>


<style lang="less">
</style>